import React from "react";
import { Link } from "react-router-dom";


const UK = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in UK</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in UK?</Link>
                      </h4>
                      <p>
                        The United Kingdom (UK) is home to some of the most
                        vibrant higher educational universities and
                        institutions. Study in UK offers the best learning
                        environment and an experience of a life-time, providing
                        that distinguished choice of destination for
                        professional education and career development. Grab it
                        before others do!
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">
                          Studying in the UK gives you a Quality Education
                        </Link>
                      </h4>
                      <p>
                        Universities in the United Kingdom focus on overall
                        holistic development of students, creating distinct
                        learning environment. The Quality Assurance Agency (QAA)
                        for Higher Education in the UK is an independent body
                        that inspects the standards and quality of all higher
                        educational institutions, periodically. Apart from
                        auditing and reviewing, it identifies the best
                        educational practices and provides constructive feedback
                        for improvement. This guarantees that all students
                        approach the best nature of learning and experience of
                        the educational courses they decide to pursue. That is
                        very reassuring, yes?
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">What does Study in UK offers</Link>
                      </h4>
                      <p>
                        The UK’s academic reputation is world-renowned for long
                        now. Universities in the UK are widely regarded as being
                        among the best in the world. They work with skilled,
                        statutory and administrative bodies and other employer
                        groups to ensure their graduates are ready for the world
                        of work. The United Kingdom is one among the world’s
                        exceptionally well-known destinations for higher
                        education, with more than 500,000 international students
                        registering every year. Whoa!?
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default UK;
