import React, { useState } from "react";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggleStudy, setToggleStudy] = useState(false);
  const [toggleservices, setToggleservices] = useState(false);
  const [toggletest, setToggletest] = useState(false);
  const [toggleDestinations, setToggleDestinations] = useState(false);

  const CloseClick = () => {
    setToggleStudy(false);
    setToggle1(false);
  };

  return (
    <>
      <div id="page" className="full-page">
        <header className="site-header site-header-transparent">
          <div className="top-header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 d-none d-lg-block">
                  <div className="header-contact-info">
                    <ul>
                      <li>
                        <Link to="tel:+9848037500">
                          <i className="fas fa-phone-alt" />
                          +91 9848037500
                        </Link>
                      </li>
                      {/* <li>
                        <i className="fas fa-map-marker-alt" /> Bengaluru
                        -560036
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
                  <div className="header-social social-links">
                    <ul>
                      <li>
                        <Link to="">
                          <i className="fab fa-facebook" aria-hidden="true" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-twitter" aria-hidden="true" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-youtube" aria-hidden="true" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-instagram" aria-hidden="true" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i className="fab fa-linkedin" aria-hidden="true" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-header" id="masthead">
            <div className="container">
              <div className="hb-group d-flex align-items-center justify-content-between">
                <div className="site-identity col-lg-3">
                  <p className="site-title">
                    <Link to="">
                      <img src="assets/img/123.png" alt="logo" />
                    </Link>
                  </p>
                </div>
                <div className="main-navigation col-lg-9 justify-content-between d-flex align-items-center">
                  <nav
                    id="menu "
                    className="navigation d-none d-lg-inline-block collapse navbar-collapse"
                  >
                    <ul>
                      <li className="current-menu-item">
                        <Link to="/">Home</Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to="/howselectstudylocation">Study Abroad</Link>
                        <ul>
                          <li>
                            <Link to="/howselectstudylocation">
                              How to select your study location
                            </Link>
                          </li>
                          <li>
                            <Link to="/advantagesinternationalstudy">
                              Advantages of international study
                            </Link>
                          </li>
                          <li>
                            <Link to="/parentguide"> Parent Guide</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/careercounselling">Our services</Link>
                        <ul>
                          <li>
                            <Link to="/careercounselling">
                              Career Counselling
                            </Link>
                          </li>
                          <li>
                            <Link to="/accommodationassistance">
                              {" "}
                              Accommodation Assistance
                            </Link>
                          </li>
                          <li>
                            <Link to="/internationaleducationcounselling">
                              International Education Counselling
                            </Link>
                          </li>
                          <li>
                            <Link to="/universitycourseselection">
                              University and Course Selection
                            </Link>
                          </li>
                          <li>
                            <Link to="/applicationguidance">
                              Application Guidance
                            </Link>
                          </li>
                          <li>
                            <Link to="/visaguidance">Visa Guidance</Link>
                          </li>
                          <li>
                            <Link to="/financialassistance">
                              Financial Assistance
                            </Link>
                          </li>
                          <li>
                            <Link to="predepartureservice">
                              Pre-Departure Service
                            </Link>
                          </li>
                          <li>
                            <Link to="/scholarshipassistance">
                              Scholarship Assistance
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Test Preparation</Link>
                        <ul>
                          <li>
                            <Link to="/toeflIeltsts">TOEFL-IELTS-PTS</Link>
                          </li>
                          <li>
                            <Link to="/gre"> GRE</Link>
                          </li>
                          <li>
                            <Link to="/gmat">GMAT</Link>
                          </li>
                          <li>
                            <Link to="/trainingeducasa">
                              Training at EDUCASA
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to="#">Study Destinations</Link>
                        <ul>
                          <li>
                            <Link to="/uk">UK</Link>
                          </li>
                          <li>
                            <Link to="/australia"> Australia</Link>
                          </li>
                          <li>
                            <Link to="/newzealand">New Zealand</Link>
                          </li>
                          <li>
                            <Link to="/usa">USA</Link>
                          </li>
                          <li>
                            <Link to="/canada">Canada</Link>
                          </li>
                          <li>
                            <Link to="/singapore"> Singapore </Link>
                          </li>
                          <li>
                            <Link to="/ireland">Ireland</Link>
                          </li>
                          <li>
                            <Link to="/italy">Italy</Link>
                          </li>
                          <li>
                            <Link to="/germany">Germany</Link>
                          </li>
                          <li>
                            <Link to="/dubai">Dubai</Link>
                          </li>
                          <li>
                            <Link to="/switzerland">Switzerland</Link>
                          </li>
                          <li>
                            <Link to="/france">France</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="joinusabcde">
                    <div className="header-btn d-inline-block">
                      <Link to="/contact" className="button-round-secondary">
                        JOIN US NOW
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-menu-container">
            <div className="slicknav_menu">
              {toggle1 === false ? (
                <>
                  <Link
                    to="#"
                    aria-haspopup="true"
                    role="button"
                    tabIndex={0}
                    className="slicknav_btn slicknav_collapsed"
                  >
                   
                    <span
                      className="slicknav_icon"
                      onClick={() => setToggle1(!toggle1)}
                    >
                      <span
                        className="slicknav_icon-bar"
                        onClick={() => setToggle1(!toggle1)}
                      />
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="#"
                    aria-haspopup="true"
                    role="button"
                    tabIndex={0}
                    className="slicknav_btn slicknav_collapsed"
                  >
                    {/* <span className="slicknav_menutxt">Close</span> */}
                    <span
                      className="slicknav_icon"
                      onClick={() => setToggle1(!toggle1)}
                    >
                      <h4 onClick={() => setToggle1(!toggle1)}>X</h4>
                    </span>
                  </Link>
                </>
              )}

              {toggle1 === true ? (
                <>
                  <nav
                    className="slicknav_nav slicknav_hidden"
                    aria-hidden="true"
                    role="menu"
                    id="toggle"
                  >
                    <ul>
                      <li
                        className="current-menu-item"
                        onClick={() => setToggle1(!toggle1)}
                      >
                        <Link to="/" role="menuitem">
                          HOME
                        </Link>
                      </li>

                      <li className="menu-item-has-children slicknav_collapsed slicknav_parent">
                        <span className="slicknav_parent-link slicknav_row">
                          <Link
                            to="#"
                            onClick={() => setToggleStudy(!toggleStudy)}
                          >
                            STUDY ABROAD
                          </Link>
                          <Link
                            to="#"
                            role="menuitem"
                            aria-haspopup="true"
                            tabIndex={-1}
                            className="slicknav_item"
                            onClick={() => setToggleStudy(!toggleStudy)}
                          >
                            <span
                              className="slicknav_arrow"
                              onClick={() => setToggleStudy(!toggleStudy)}
                            >
                              <i className="fas fa-plus" />
                            </span>
                          </Link>
                        </span>
                        {toggleStudy === true ? (
                          <>
                            <ul
                              role="menu"
                              className="slicknav_hidden"
                              aria-hidden="true"
                            >
                              <li>
                                <Link
                                  to="/howselectstudylocation"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  How to select your study location
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/advantagesinternationalstudy"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Advantages of international study
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/parentguide"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Parent Guide
                                </Link>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>

                      <li className="menu-item-has-children slicknav_collapsed slicknav_parent">
                        <span
                          className="slicknav_parent-link slicknav_row"
                          onClick={() => setToggleservices(!toggleservices)}
                        >
                          <Link to="#">Our services</Link>
                          <Link
                            to="#"
                            role="menuitem"
                            aria-haspopup="true"
                            tabIndex={-1}
                            className="slicknav_item"
                            onClick={() => setToggleservices(!toggleservices)}
                          >
                            <span
                              className="slicknav_arrow"
                              onClick={() => setToggleservices(!toggleservices)}
                            >
                              <i className="fas fa-plus" />
                            </span>
                          </Link>
                        </span>

                        {toggleservices === true ? (
                          <>
                            <ul
                              role="menu"
                              className="slicknav_hidden"
                              aria-hidden="true"
                            >
                              <li>
                                <Link
                                  to="/careercounselling"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Career Counselling
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/accommodationassistance"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Accommodation Assistance
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/internationaleducationcounselling"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  International Education Counselling
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/universitycourseselection"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  University and Course Selection
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/applicationguidance"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Application Guidance
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/visaguidance"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Visa Guidance
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/financialassistance"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Financial Assistance
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/predepartureservice"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Pre-Departure Service
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/scholarshipassistance"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Scholarship Assistance
                                </Link>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>

                      <li className="menu-item-has-children slicknav_collapsed slicknav_parent">
                        <span
                          className="slicknav_parent-link slicknav_row"
                          onClick={() => setToggletest(!toggletest)}
                        >
                          <Link to="#">Test Preparation </Link>
                          <Link
                            to="#"
                            role="menuitem"
                            aria-haspopup="true"
                            tabIndex={-1}
                            className="slicknav_item"
                            onClick={() => setToggletest(!toggletest)}
                          >
                            <span
                              className="slicknav_arrow"
                              onClick={() => setToggletest(!toggletest)}
                            >
                              <i className="fas fa-plus" />
                            </span>
                          </Link>
                        </span>

                        {toggletest === true ? (
                          <>
                            <ul
                              role="menu"
                              className="slicknav_hidden"
                              aria-hidden="true"
                            >
                              <li>
                                <Link
                                  to="/toeflIeltsts"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  TOEFL-IELTS-PTS
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/gre"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  GRE
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/gmat"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  GMAT
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/trainingeducasa"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Training at EDUCASA
                                </Link>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>

                      <li className="menu-item-has-children slicknav_collapsed slicknav_parent">
                        <span
                          className="slicknav_parent-link slicknav_row"
                          onClick={() =>
                            setToggleDestinations(!toggleDestinations)
                          }
                        >
                          <Link to="#"> Study Destinations</Link>
                          <Link
                            to="#"
                            role="menuitem"
                            aria-haspopup="true"
                            tabIndex={-1}
                            className="slicknav_item"
                            onClick={() =>
                              setToggleDestinations(!toggleDestinations)
                            }
                          >
                            <span
                              className="slicknav_arrow"
                              onClick={() =>
                                setToggleDestinations(!toggleDestinations)
                              }
                            >
                              <i className="fas fa-plus" />
                            </span>
                          </Link>
                        </span>

                        {toggleDestinations === true ? (
                          <>
                            <ul
                              role="menu"
                              className="slicknav_hidden"
                              aria-hidden="true"
                            >
                              <li>
                                <Link
                                  to="/uk"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  UK
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/australia"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Australia
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/newzealand"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  New Zealand
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/usa"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  USA
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/canada"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Canada
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/singapore"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Singapore
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ireland"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Ireland
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/italy"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Italy
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/germany"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Germany
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/dubai"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Dubai
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/switzerland"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  Switzerland
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/france"
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() => CloseClick()}
                                >
                                  France
                                </Link>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>

                      <li>
                        <Link
                          to="/contact"
                          role="menuitem"
                          onClick={() => setToggle1(!toggle1)}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Hadear;
