import React from 'react'
import { Link } from "react-router-dom";


const Singapore = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Singapore</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in Singapore</Link>
                      </h4>
                      <p>
                        The developed country of Singapore holds the title of
                        being the most modern South Asian country for over a
                        decade. This country-city- Island is the only of its
                        kind globally and is often confused by its status.
                        Singapore is a diverse country with a generous mix of
                        Chinese, Malays, and Indians, among other ethnic groups.
                        English, Tamil, Mandarin, and Malay are four recognized
                        languages in Singapore. Malay is the national language
                        of Singapore; and is as widely used as English.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why Study in Singapore?</Link>
                      </h4>
                      <p>
                        The rich diversity, safe environment, world-class
                        education, and affordable fees have compelled
                        international students to consider study in Singapore.
                        Singapore welcomed 78,000 international students in the
                        year 2019, and the number is only growing. The proximity
                        of Singapore to India has made it a popular destination
                        for Indian students. Singapore has one of the best
                        systems of education in the world, made evident in the
                        standing of its universities globally
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Education System in Singapore</Link>
                      </h4>
                      <p>
                        Singapore’s education system comprises preschool,
                        primary school, secondary school, pre-university
                        education, and university education. Education in the
                        country is controlled and scrutinized by The Ministry of
                        Education (MOE). The constant effort of the MOE has put
                        Singapore on the list of the best education systems in
                        the world.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Singapore