import React from "react";
import { Link } from "react-router-dom";


const ScholarshipAssistance = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Scholarship Assistance</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="title-divider-center" />
          {/* <h2 className="about-title text-center">
            Introduction About Our Institute
          </h2> */}
          <div className="row">
            <div className="col-md-6">
              <p className="about-describe info-left">
                A scholarship is a monetary award given to a worthy student to
                support their education. These are distributed to students who
                apply and fulfil a list of requirements laid forth by the
                issuing organisation. Scholarships are very useful in enabling
                students to study abroad without having to worry about the cost.
              </p>
            </div>
            <div className="col-md-6">
              <p className="about-describe info-right">
                The amounts awarded can vary, but they normally go towards
                paying for housing and tuition. Among the scholarships offered
                are.Scholarships Awarded based on merit These awards are made to
                students who have demonstrated academic or athletic excellence
                and have gone on to succeed in their chosen fields of study.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="home-course-section"
        style={{ background: "white", padding: "2px" }}
      >
        <div className="container">
          <div className="overlay-wrapper">
            <div className="row"></div>
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Dedicated Grants for Students</Link>
                      </h4>
                      <p>
                        They are distributed to pupils based on their gender,
                        race, religion, ethnicity, or clinical background.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Need-based financial aid.</Link>
                      </h4>
                      <p>
                        Need-based scholarships are grants given to eligible
                        students in order to help them pay for their educational
                        expenses. The benefits and financial situation of the
                        student and their family are the only factors that
                        determine eligibility.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">
                          Scholarships based on programs/career.
                        </Link>
                      </h4>
                      <p>
                        They are provided by people working with a college,
                        university, or other organisation. Students receive them
                        based on their academic records and accomplishments
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScholarshipAssistance;
