import React from "react";
// import { IconName,} from "react-icons/fa";
import { Link } from "react-router-dom";

const PopularCourses = () => {
  return (
    <>
      <section className="home-service">
        <div className="pattern-overlay" />
        <div className="container">
          <div className="service-wrapper">
            <div className="pattern-circle overlay" />
            <div className="service-content">
              <div className="section-head">
                <div className="title-divider" />
                <h2>Our Courses</h2>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-Helmet" />
                </div>
                <h4 className="course-title">
                  <Link to="">Accounting and Finance</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-pencil_art" />
                </div>
                <h4 className="course-title">
                  <Link to="">Art and Design</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-microscope" />
                </div>
                <h4 className="course-title">
                  <Link to="">Biological and Life Sciences</Link>
                </h4>
              </div>
            </div>
            <div className="service-content">
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-design" />
                </div>
                <h4 className="course-title">
                  <Link to="">Computing and IT</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-Consultancy" />
                </div>
                <h4 className="course-title">
                  <Link to="">Business and Management</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-pencil_art" />
                </div>
                <h4 className="course-title">
                  <Link to="">Economics</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-Consultancy" />
                </div>
                <h4 className="course-title">
                  <Link to="">Political and Social Sciences</Link>
                </h4>
              </div>
            </div>
            <div className="service-content">
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-pencil_art" />
                </div>
                <h4 className="course-title">
                  <Link to="">Engineering</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-Consultancy" />
                </div>
                <h4 className="course-title">
                  <Link to="">Environmental Sciences</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-pencil_art" />
                </div>
                <h4 className="course-title">
                  <Link to="">Health and Medicine</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-pencil_art" />
                </div>
                <h4 className="course-title">
                  <Link to="">Hospitality and Tourism</Link>
                </h4>
              </div>
            </div>
            <div className="service-content">
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-design" />
                </div>
                <h4 className="course-title">
                  <Link to="">Law and Legal Studies</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-microscope" />
                </div>
                <h4 className="course-title">
                  <Link to="">Marketing, Media and Communication</Link>
                </h4>
              </div>
              <div className="course-type">
                <div className="course-icon">
                  <i aria-hidden="true" className="icon icon-Helmet" />
                </div>
                <h4 className="course-title">
                  <Link to="">Teaching and Education</Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularCourses;
