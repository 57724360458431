import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './layout/Footer'
import Hadear1 from './layout/Hadear1'
import Hadear from './layout/Hadear'

const MainLayOut = () => {
  return (
    <>
      {/* <Hadear1 /> */}
      <Hadear />
      <Outlet />
      <Footer />
    </>
  );
}

export default MainLayOut