import React from 'react'

const ToeflIeltsPts = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">TOEFL – IELTS-PTE</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/educator-img13.jpg" alt="" />
                </figure>

                <div className="title-divider" />
                <p className="about-desc">
                  The Test of English as a Foreign LanguageTM (TOEFL) measures
                  the ability of nonnative speakers of English to use and
                  understand English as it is spoken, written, and heard in
                  college and university settings. The TOEFL tests all four
                  language skills that are important for effective
                  communication: Speaking, Listening, Reading, and Writing. The
                  test helps students demonstrate that they have the English
                  skills needed for success. TOEFL also emphasizes integrated
                  skills and provides better information to institutions about
                  students' ability to communicate in an academic setting and
                  their readiness for academic coursework. TOEFL will be needed
                  for Education in USA, UK, Canada, and New Zealand.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className=" about-right-content">
                <p className="about-desc">
                  The TOEFL test is developed and administered by the US-based
                  "Educational Testing Service" (ETS). This implies that ETS
                  sets the questions, conducts the test, and sends each examinee
                  the score report. For the conduct of the test, ETS has
                  appointed Testing Agencies in various countries, which act as
                  franchisee for ETS. In India, this agency is the "Prometric
                  Testing Services Private Limites" which administers the test
                  at various centers across India.
                </p>
                <p className="about-desc">
                  Computer based TOEFL test is held all-round-the-year. Unlike
                  other exams, you can choose your own date and time for taking
                  the TOEFL test. September to December is the high season for
                  TOEFL test, so in case you intend to take the test during this
                  period, you need to register very early (say 90 days in
                  advance) to get a date of your choice. Otherwise, registering
                  at least three days in advance is mandatory. The test lasts
                  roughly four hours.
                </p>

                <figure className="figure-round-border">
                  <img src="assets/img/educator-img12-500px.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>
      <br />
    </>
  );
}

export default ToeflIeltsPts