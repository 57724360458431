import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


const Contact = () => {

  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

        "service_0mt11j5",
        "template_1x6nr8m",
        "#myForm",
        "RnYOVlDPnYyYN8khd"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">contact Us</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-section">
          <div className="container">
            <div className="contact-form-inner">
              <div className="pattern-overlay zigzag-patten" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="contact-detail-container">
                    <div className="section-head">
                      <div className="title-divider" />
                      <h2 className="section-title">Contact Us!</h2>
                    </div>
                    <div className="contact-details-list">
                      <ul>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-phone1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Phone Number :</h5>

                            <span className="text-center mt-lg-3">
                              {" "}
                              {/* +91 9380293380 */}
                              <p className="mb-2">
                                <a
                                  href="tel:+91 9848037500"
                                  style={{ color: "black" }}
                                >
                                  (+91) 9848037500{" "}
                                </a>
                              </p>
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-map-marker1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Location Address :</h5>
                            <span className="mt-lg-3">
                              #16, first floor, Sri Sai Complex, above Akshara Super market  opposite to BSNL exchange office BEML layout, Srirampura Mysuru-570008
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-envelope3"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Email address :</h5>
                            <a style={{ color: "black" }} href="mailto:info@educasainternational.com">info@educasainternational.com
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-from-wrap">
                    <div className="pattern-overlay circle-patten" />
                    <h5 className="cheakout-title text-center">
                      Fill in your details and we’ll call you back.
                    </h5>

                    <form className="contact-from" onSubmit={sendEmail} id="myForm">
                      <p>
                        <input
                          type="text"
                          name="from_name"
                          placeholder="First Name*"
                          onChange={(e) => nameChange(e)}
                          required
                          value={name}
                        />
                      </p>
                     
                      <p>
                        <input type="email" placeholder="Email*"
                          name='email'
                          onChange={(e) => mailChange(e)}
                          value={mail}
                          required

                        />
                      </p>

                      <p>
                        <input
                          type="number"
                          name="number"
                          placeholder=" Number*"
                          onChange={(e) => numberChange(e)}
                          value={number}
                          required
                        />
                      </p>

                       <p>
                        <input
                          type="text"
                          placeholder="Message*"
                          onChange={(e) => massagesChange(e)}
                          name='massage'
                          value={massages}
                          required
                        />
                      </p>

                      {/* <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>Nearest Envision Office</option>
                            <option value={0}>Amritsar</option>
                            <option value={0}>Ahmedabad</option>
                            <option value={0}>Bangalore</option>
                            <option value={0}>Chandigarh</option>
                            <option value={0}>Chennai</option>
                            <option value={0}>Coimbatore</option>
                            <option value={0}>Delhi</option>
                            <option value={0}>Hyderabad</option>
                            <option value={0}>Indore</option>
                            <option value={0}>Jaipur</option>
                            <option value={0}>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>
                              Preferred Study Destination
                            </option>
                            <option value={0}>Australia</option>
                            <option value={0}>UK</option>
                            <option value={0}>USA</option>
                            <option value={0}>Dubai</option>
                            <option value={0}>Italy</option>
                            <option value={0}>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>
                              When do you plan to study?
                            </option>
                            <option value={0}>Jan-23</option>
                            <option value={0}>fab-23</option>
                            <option value={0}>mar-23</option>
                            <option value={0}>apr-23</option>
                            <option value={0}>may-23</option>
                            <option value={0}>jun-23</option>
                            <option value={0}>july-23</option>
                            <option value={0}>aug-23</option>
                            <option value={0}>sep-23</option>
                            <option value={0}>oct-23</option>
                            <option value={0}>nov-23</option>
                            <option value={0}>dec-23</option>
                          </select>
                        </div>
                      </div> */}
                      <a className="width-full text-center">
                        <input
                          type="submit"
                          name="submit"
                          defaultValue="Submit Now"
                        />
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;
