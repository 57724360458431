import React from "react";
import { Link } from "react-router-dom";

const HomeServices = () => {
  return (
    <>
      <section className="home-course-section">
        <div className="container">
          <div className="overlay-wrapper">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 ">
                <br />
                <h2 className="section-title ">Our Services</h2>
                <div className="title-divider" />
              </div>
            </div>
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/3.jpg" alt />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/careercounselling">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Career Counselling</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/accommodationassistance">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Accommodation Assistance</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/internationaleducationcounselling">
                          More
                        </Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">International Education Counselling</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img31.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="universitycourseselection">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">University and Course Selection</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/4.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/applicationguidance">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Application Guidance</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/1.png" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/visaguidance">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Visa Guidance</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/5.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/financialassistance">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Financial Assistance</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/6.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/predepartureservice">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Pre-Departure Service</Link>
                      </h4>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-3 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/7.jpg" alt />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                      <span className="cat-meta">
                        <Link to="/scholarshipassistance">More</Link>
                      </span>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Scholarship Assistance</Link>
                      </h4>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
