import React from "react";
import { Link } from "react-router-dom";


const InternationalDestinations = () => {
  return (
    <>
      <div className="product-tab-outer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 right-sidebar">
              <div className="related-product">
                <div className="related-title">
                  <h3>International Destinations</h3>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/usa.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/france.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/germany.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/ireland.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/italy.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/newzealend.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/singapore.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/switzerland.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/uk.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/australia.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/canada.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="product-item text-center">
                      <figure className="product-image">
                        <Link to="">
                          <img src="assets/img/dubai.png" alt />
                        </Link>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternationalDestinations;
