import React from 'react'
import { Link } from "react-router-dom";


const Germany = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Germany</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in Germany</Link>
                      </h4>
                      <p>
                        One of the largest European countries, Germany – frames
                        a vast variety of landscapes in its tall mountains,
                        green hills, sandy and dry plains, and agricultural
                        pastures. Not only does Germany bestow the world with
                        its uniqueness, it, over the years has developed a
                        robust and superior educational system.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in Germany?</Link>
                      </h4>
                      <p>
                        Germany is one of the best countries for education. Over
                        13 per cent of students from around the globe study in
                        Germany, making it the fourth most attractive study
                        destination for international students. More than 25,000
                        Indian students study in Germany in the period of
                        2019-2020. German education focuses on providing
                        excellent teaching and research. International students
                        can earn highly valued German degrees over a wide
                        variety of courses.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Master’s program</Link>
                      </h4>
                      <p>
                        At public institutions consecutive master programs are
                        cost free. The term consecutive applies to a program
                        from a related bachelor’s degree from Germany.
                        Non-consecutive students pursuing masters degrees at a
                        Public Germany University may pay around €20,000, while
                        at private university the tuition fee could add up to
                        €30,000 per year. Ph.D. tuitions are free for at most 6
                        semesters at all universities of Germany. Ph.D. Students
                        are required to pay €250 towards administration cost.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Germany