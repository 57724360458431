import React from "react";
import { Link } from "react-router-dom";

const Italy = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Italy</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in the Italy</Link>
                      </h4>
                      <p>
                        One of the most popular travel destinations in Europe,
                        the beautiful country of Italy is also a favorite study
                        destination for many international students. The
                        mountains of Dolimite and Apennine, the Italian Rivera,
                        the famous Colosseum; Italy offers its unfathomable
                        beauty, heritage and architecture to be marveled. A
                        visit to the St. Peter’s Basilica in the Vatican and the
                        Sistine Chapel may get one a glimpse of the Pope. The
                        beautiful water city of Venice guarantees its visitors a
                        view that is sure to remain fossiled in their memories
                        for times to come.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in Italy?</Link>
                      </h4>
                      <p>
                        Italy is a land that proficiently speaks the language of
                        art, history, and physics. Home to extraordinary minds
                        like Leonardo da Vinci and Galileo Galilei; Italy has
                        come to be associated as a country proficient in applied
                        sciences. Italian minds are generations ahead, while the
                        world was introduced to the Renaissance,
                        industrialization; the works of Guglielmo Marconi laid
                        the foundation of wireless communication; which the
                        world today cannot be fathomed without.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to=""> Quality of Education</Link>
                      </h4>
                      <p>
                        Italian Universities are among the oldest in the world.
                        The country has given to the world – leading scientist,
                        revolutionaries, artist, and many Noble Prize laureates.
                        Italian universities are acclaimed around the globe and
                        stand as epitomes in fields of culture, Architecture,
                        Applied Sciences, Art, Medicine, Engineering, Industrial
                        Designs, and Economics. The quality of education offered
                        through all institutions of Italy is unmatched. The
                        combination of superior teaching and the openness of the
                        country to welcome international students, have made
                        many overseas students turn to Italy in dreams of
                        crafting successful educational and career paths.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Italy;
