import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import MainLayOut from "./components/MainLayOut";
import Home from "./pages/Home";
import Contact from "./pages/home/Contact";
import HowSelectStudyLocation from "./pages/studyabroad/HowSelectStudyLocation";
import AdvantagesInternationalStudy from "./pages/studyabroad/AdvantagesInternationalStudy";
import ParentGuide from "./pages/studyabroad/ParentGuide";
import CareerCounselling from "./pages/ourservices/CareerCounselling";
import AccommodationAssistance from "./pages/ourservices/AccommodationAssistance";
import InternationalEducationCounselling from "./pages/ourservices/InternationalEducationCounselling";
import UniversityCourseSelection from "./pages/ourservices/UniversityCourseSelection";
import ApplicationGuidance from "./pages/ourservices/ApplicationGuidance";
import VisaGuidance from "./pages/ourservices/VisaGuidance";
import FinancialAssistance from "./pages/ourservices/FinancialAssistance";
import PreDepartureService from "./pages/ourservices/PreDepartureService";
import ScholarshipAssistance from "./pages/ourservices/ScholarshipAssistance";
import ToeflIeltsPts from "./pages/testpreparation/ToeflIeltsPts";
import GRE from "./pages/testpreparation/GRE";
import GMAT from "./pages/testpreparation/GMAT";
import TrainingEducasa from "./pages/testpreparation/TrainingEducasa";
import AttendMockSession from "./pages/AttendMockSession";
import Australia from "./pages/studydestinations/Australia";
import UK from "./pages/studydestinations/UK";
import France from "./pages/studydestinations/France";
import Switzerland from "./pages/studydestinations/Switzerland";
import Dubai from "./pages/studydestinations/Dubai";
import Germany from "./pages/studydestinations/Germany";
import Italy from "./pages/studydestinations/Italy";
import Ireland from "./pages/studydestinations/Ireland";
import Singapore from "./pages/studydestinations/Singapore";
import Canada from "./pages/studydestinations/Canada";
import USA from "./pages/studydestinations/USA";
import NewZealand from "./pages/studydestinations/NewZealand";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainLayOut />}>
          <Route index element={<Home />} />
          <Route
            path="/howselectstudylocation"
            element={<HowSelectStudyLocation />}
          />
          <Route
            path="/advantagesinternationalstudy"
            element={<AdvantagesInternationalStudy />}
          />

          <Route path="/parentguide" element={<ParentGuide />} />
          <Route path="/careercounselling" element={<CareerCounselling />} />
          <Route
            path="/accommodationassistance"
            element={<AccommodationAssistance />}
          />
          <Route
            path="/internationaleducationcounselling"
            element={<InternationalEducationCounselling />}
          />
          <Route
            path="/universitycourseselection"
            element={<UniversityCourseSelection />}
          />
          <Route
            path="/applicationguidance"
            element={<ApplicationGuidance />}
          />
          <Route path="/visaguidance" element={<VisaGuidance />} />
          <Route
            path="/financialassistance"
            element={<FinancialAssistance />}
          />
          <Route
            path="/predepartureservice"
            element={<PreDepartureService />}
          />
          <Route
            path="/scholarshipassistance"
            element={<ScholarshipAssistance />}
          />
          <Route path="/toeflIeltsts" element={<ToeflIeltsPts />} />
          <Route path="/gre" element={<GRE />} />
          <Route path="/gmat" element={<GMAT />} />
          <Route path="/trainingeducasa" element={<TrainingEducasa />} />
          <Route path="/attendmocksession" element={<AttendMockSession />} />
          <Route path="/australia" element={<Australia />} />
          <Route path="/uk" element={<UK />} />
          <Route path="/france" element={<France />} />
          <Route path="/switzerland" element={<Switzerland />} />
          <Route path="/dubai" element={<Dubai />} />
          <Route path="/germany" element={<Germany />} />
          <Route path="/italy" element={<Italy />} />
          <Route path="/ireland" element={<Ireland />} />
          <Route path="/singapore" element={<Singapore />} />
          <Route path="/canada" element={<Canada />} />
          <Route path="/usa" element={<USA />} />
          <Route path="/newzealand" element={<NewZealand />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
