import React from "react";
import { Link } from "react-router-dom";

const InternationalEducationCounselling = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">
                internationa Leducation Counselling
              </h3>
            </div>
          </div>
        </div>
      </section>

      <main id="content" className="site-main">
        <div className="course-detail-section">
          <div className="pattern-overlay circle-patten" />
          <div className="course-detail-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="course-detail-container">
                    <div className="tab-container">
                      <div className="responsive-tabs">
                        <div
                          id="nav-tab-content"
                          className="tab-content"
                          role="tablist"
                        >
                          <div
                            id="pane-A"
                            className="card tab-pane fade show active"
                            role="tabpanel"
                            aria-labelledby="tab-A"
                          >
                            <div
                              className="card-header"
                              role="tab"
                              id="heading-A"
                            >
                              <h5 className="mb-0">
                                <a
                                  data-bs-toggle="collapse"
                                  href="#collapse-A"
                                  aria-expanded="true"
                                  aria-controls="collapse-A"
                                >
                                  Overview
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-A"
                              className="collapse show"
                              data-bs-parent="#nav-tab-content"
                              role="tabpanel"
                              aria-labelledby="heading-A"
                            >
                              <div className="card-body">
                                <h5>Free Counselling</h5>
                                <p>
                                  We provide free counselling sessions at
                                  Educasa. A student's educational profile and
                                  growth trajectory can be significantly
                                  improved by the vast array of course
                                  possibilities provided by overseas educational
                                  institutions.
                                </p>

                                <h5>Educasa Consultants</h5>
                                <p>
                                  There are many various career options
                                  available around the world, including those in
                                  design, engineering, marine sciences, mass
                                  communication, business studies, and many
                                  more. In addition, there are many different
                                  nations from which to choose when making
                                  travel plans. This will inevitably turn into a
                                  difficult choice and a drawn-out procedure.
                                  <br />
                                  <br />
                                  With relation to a student's academic path,
                                  the counsellors at Educasa are trained to
                                  evaluate the student's potential, assist in
                                  identifying opportunities, and create plans to
                                  turn vision into reality. The Educasa Our
                                  committed team of consultants has been working
                                  internationally for more than 25 years, and
                                  they have directly trained students and
                                  visited colleges all around the world. Also,
                                  we have country specialists with over 10 to 15
                                  years of expertise in the field who will
                                  individually assist you in selecting the
                                  university and programme that are most
                                  compatible with your abilities, interests, and
                                  passions.
                                </p>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 order-lg-first mt-lg-5">
                  <div className="sidebar">
                    <div className="video-widget">
                      <img src="assets/img/educator-img13.jpg" alt="" />
                    </div>

                    <h5 className="mt-lg-5"><br/>
                      We provide the next services. Free{" "}
                    </h5>
                    <ul>
                      <li>Individualized academic guidance.</li>
                      <li>
                        Shortlisting suitable study locations, such as the
                        United States, the United Kingdom, Australia, Singapore,
                        New Zealand, etc. –
                      </li>
                      <li>
                        Choosing institutions and colleges - Eligibility
                        evaluation - Application support
                      </li>
                      <li>
                        Advice and editing for statements of purpose and resumes
                        - Registration and standardised test preparation.
                      </li>
                      <li>
                        Registration and instruction in English as a foreign
                        language; Information on scholarships and bank loans;
                        Help with entrance and enrolment procedures; Assistance
                        with visa applications and documentation review.
                      </li>
                      <li>Financial support for health insurance.</li>
                      <li>
                        Foreign exchange and international banking support.
                      </li>
                      <li>
                        Assistance with flights and purchasing tickets -
                        Pre-Departure services.
                      </li>
                    </ul>
                    <p className="mt-lg-5">
                      <b>
                        Please fill out the adjacent form to schedule your free
                        counselling appointment.
                      </b>
                    </p>

                    <Link
                      to="/contact"
                      className="button-round-secondary mt-lg-5"
                    >
                      JOIN US NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default InternationalEducationCounselling;
