import React from 'react'

const GMAT = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">GMAT</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="home-goal-section categories-goal-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="goal-content">
                <div className="pattern-overlay" />
                <div className="title-divider" />
                <p className="goal-info">
                  The GMAT Test stands for Graduate Management Admission Test.
                  It is a standardized assessment that helps business schools
                  assess the qualifications of applicants for advanced study in
                  business and management. Schools use the test as one predictor
                  of academic performance in an MBA program or in other graduate
                  management programs. GMAT is accepted by countries like USA,
                  Canada, Singapore etc. It is the most commonly accepted test
                  for Graduate Management (MBA) programs worldwide by most
                  Universities and Business Schools.
                </p>
                <p className="goal-info">
                  The GMAT exam measures basic verbal, mathematical, and
                  analytical writing skills that you have developed over a long
                  period of time in your education and work. It does NOT measure
                  your knowledge of business, your job skills, specific content
                  in your undergraduate or first university course work, your
                  abilities in any other specific subject area, or subjective
                  qualities—such as motivation, creativity, and interpersonal
                  skills.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="inner-goal-image">
                <figure className="video-play-image">
                  <img src="assets/img/educator-img13.jpg" alt="" />
                  <div className="overlay02 overlay" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-lg-5"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default GMAT