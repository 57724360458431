import React from "react";
import { Link } from "react-router-dom";

const Dubai = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Dubai</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in Dubai</Link>
                      </h4>
                      <p>
                        Dubai is the largest and the most populated city in the
                        United Arab Emirates. Dubai is a land of culture and
                        opportunity. 200 ethnicities contribute 2,780,000
                        residents to the city. Dubai is strategically located
                        and is at the center of trade, making Dubai an ideal
                        location. Residents enjoy ultra-modern architecture,
                        tax-free living, and modern technology. Dubai sees a
                        large number of international visitors that visit the
                        city to experience luxury stays. Underpinning all the
                        luxury is the rich Arabic culture
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Work while studying in the UAE</Link>
                      </h4>
                      <p>
                        Indian students in Dubai can work for 20 hours a week
                        and 40 hours on holidays after seeking the permission of
                        their university. Likewise, a student is required to
                        take the consent of their university before accepting an
                        internship. An Indian student studying in Dubai
                        potentially earns 15-30 AED per hour from their
                        part-time jobs. International students see this
                        opportunity as a way to acquire practical knowledge and
                        support their expenses. There are many positives to
                        working while studying as an international student.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Working on Campus</Link>
                      </h4>
                      <p>
                        Most universities in Dubai allow students to work
                        on-campus. Universities believe that working allows a
                        student to gain hands-on experience, extending over
                        academics. Students are employed in libraries, college
                        and administrative offices, departments, or across
                        university outlets.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Dubai;
