import React from 'react'
import { Link } from "react-router-dom";


const Switzerland = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Switzerland</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in Switzerland?</Link>
                      </h4>
                      <p>
                        Switzerland is a multilingual country with German,
                        French, Italian, and Romansh for its four official
                        languages. Switzerland welcomes immigrants from all
                        around the world. 25 percent of Switzerland’s population
                        are foreigners. The Indian community is 24,567 strong
                        and is mostly concentrated around Zurich, Geneva, Basel,
                        Baden, Bern, and Lausanne. About 1000 Indian students
                        are studying in Switzerland.
                      </p>
                      <br />
                      <p>
                        Swiss universities are high performers, and over the
                        years, have come to be known as providers of superior
                        quality education. Switzerland is picked by
                        international students for the diversity in its choice
                        over a breath of study fields. In places best suited for
                        international students, Switzerland placed third in
                        Europe
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img29.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Climate of Switzerland</Link>
                      </h4>
                      <p>
                        Summers in Switzerland are pleasantly warm, with daytime
                        temperatures ranging from 18oC to 28oC. Winters are
                        cold, with temperatures falling to lows of 7oC to -2oC.
                        Springtime temperatures range between 8oC to 15oC. Rain
                        showers are light.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/educator-img30.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Education System of Switzerland</Link>
                      </h4>
                      <p>
                        The education system of Switzerland is comprehensive,
                        robust, and is considered among the best in the world.
                        The country of Switzerland is made up of cantons. Every
                        canton has its government, constitution, and laws. There
                        are a total of 20 cantons and 6 half cantons. Every
                        canton decides for itself the curriculum, academic
                        calendar, and criteria. Despite the differences, the
                        entire system is tied together by the central government
                        under the watchful eye of the State Secretariat for
                        Education, Research and Innovation (SERI).
                      </p><br/>
                      <p>
                        Despite the diversity students have all to gain, the
                        Swiss government takes into account the capabilities and
                        ability of its students. Education in Switzerland is
                        compulsory for at least 9 years; after school students
                        branch out choosing their career paths, post realizing
                        their abilities and yearning.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Switzerland